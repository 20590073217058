@-webkit-keyframes move {
  from {
    width: 0;
  }

  to {
    width: 80%;
  }
}

@keyframes move {
  from {
    width: 0;
  }

  to {
    width: 80%;
  }
}

.container {
  transition: opacity 0.5s ease-out;
}

.bar {
  @apply bg-brand-100;
  height: 2px;
}

.move {
  -webkit-animation: move 2s;
  animation: move 2s forwards;
}
