.featured {
  text-decoration: none;
  background-size: 0 1px, 100% 1px, 0 1px;
  background-position: 0 80%, 100% 80%, 0 80%;
  background-repeat: no-repeat;
  transition: background-size 0.3s ease-in-out,
    background-position 0.3s ease-in-out;

  &.defaultLine {
    background-image: linear-gradient(
        to right,
        theme(colors.brand-100) 0%,
        theme(colors.brand-100) 100%
      ),
      linear-gradient(
        to right,
        theme(colors.neutral-30) 0%,
        theme(colors.neutral-30) 100%
      );
  }

  &.transparentLine,
  &.blackLine {
    background-image: linear-gradient(
        to right,
        theme(colors.brand-100) 0%,
        theme(colors.brand-100) 100%
      ),
      linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &.whiteLine {
    background-image: linear-gradient(
        to right,
        theme(colors.white) 0%,
        theme(colors.white) 100%
      ),
      linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &.fullBlue:hover,
  &.blueLine {
    background-image: linear-gradient(
        to right,
        theme(colors.bparts-100) 0%,
        theme(colors.bparts-100) 100%
      ),
      linear-gradient(
        to right,
        theme(colors.neutral-30) 0%,
        theme(colors.neutral-30) 100%
      );
  }

  &:hover {
    background-size: 100% 1px, 100% 1px, 0 1px;
  }
}
