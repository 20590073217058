.legalList {
  columns: 1;

  @media (min-width: 1024px) {
    columns: 3;
    gap: 0;
  }
}

.deviderFix {
  position: relative;
}

.featured {
  &::before {
    @apply bg-transparent;
    position: absolute;
    content: '';
    height: 1px;
    width: 100%;
    bottom: 5px;
    left: 0;
  }

  &::after {
    @apply bg-white;
    position: absolute;
    content: '';
    height: 1px;
    width: 0;
    bottom: 0;
    left: 0;
    transition: width 0.3s ease-in-out;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }
}

.featured-dark {
  &::after {
    @apply bg-neutral-50;
  }
}

/*
@media (min-width: 1024px) {
  .footer::before {
    content: url('/svg/dividers/footer/top-lg.svg');
  }
}
*/

.deviderFix::after {
  @apply bg-white;
  content: url('/app/1/svg/dividers/footer/bottom-xs.svg');
  display: block;

  @media (min-width: 1024px) {
    content: none;
  }
}

@media print {
  .isPrivateArea,
  .footerUpperCurve,
  .checkoutFooter {
    display: none;
  }
}

.footerBparts {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 31px;
  row-gap: 48px;
  grid-template-areas:
    'sitemap payment selectLanguageContainer'
    'sitemap shipping store'
    'sitemap socials stellantisAndSustinera';
}

.footerBpartsCheckout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100, 1fr));
  grid-template-areas: 'shipping socials logos';
}

@media not all and (min-width: 1024px) {
  .footerBparts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'sitemap sitemap'
      'payment payment'
      'shipping shipping'
      'selectLanguageContainer selectLanguageContainer'
      'store store'
      'stellantisAndSustinera stellantisAndSustinera'
      'socials socials';
  }
}

.sitemap {
  grid-area: sitemap;
}

.logos {
  grid-area: sitemap;
}

.payment {
  grid-area: payment;
  align-items: flex-end;
}

.store {
  grid-area: store;
  align-items: flex-end;
}

.shipping {
  grid-area: shipping;
  align-items: flex-end;
}

.stellantis {
  grid-area: stellantis;
  align-items: flex-end;
}

.socials {
  grid-area: socials;
  align-items: flex-end;
}

.sustainera {
  grid-area: sustainera;
  align-items: flex-end;
}

.selectLanguageContainer {
  grid-area: selectLanguageContainer;
  align-items: flex-end;
}

.stellantisAndSustinera {
  grid-area: stellantisAndSustinera;
  align-items: flex-end;
}
