.advanced-wrapper {
  @apply grid;

  grid-template-columns: 375px 1fr;
}

.search-column {
  top: 48px;
  height: calc(100vh - 48px);
}
